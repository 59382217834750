<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>“创客北京2019”创新创业大赛海淀区级赛 颁奖典礼成功举办 </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>“创客北京2019”创新创业大赛海淀区级赛 颁奖典礼成功举办 </h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/7/25</span>
          <span class="time-title">创客北京2019”创新创业大赛海淀区级赛</span>
          <span class="gold">一等奖
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">7月25日,“创客北京2019”创新创业大赛海淀区级赛颁奖典礼在海淀创业园举办。经过复赛的激烈角逐和精彩比拼，海淀区55个企业和项目获得区级赛奖项，晋级市级赛。北京市经济和信息化局中小企业处调研员辛欣，北京市中小企业服务中心副主任王福强，北京国融工发投资管理有限公司董事长韩凌，北京市中小企业公共服务平台总经理助理刘洋，中国建设银行北京上地支行行长白煜，海淀园管委会企业发展促进处调研员崔巅博，海淀园管委会企业发展促进处副处长和武杰，海淀园创业服务中心主任赵新良，以及获奖企业代表，投资机构、服务机构代表等出席了典礼仪式。</p>
        <div class="firth">
          <div><img src="@/assets/img/beijnig1.jpeg" alt=""></div>
        </div>
        <p class="fourth-center">海淀园企业发展促进处调研员崔巅博致辞</p>
        <p class="fourth-p2">海淀园企业发展促进处调研员崔巅博代表主办方中关村科技园区海淀园管理委员会致辞。他表示，海淀区作为全市乃至全国的科技创新出发地、原始创新策源地、自主创新主阵地，积极助力北京市构建高精尖经济结构，推动中小企业双创升级。目前，全区平均每天诞生50家科技企业，全年新增注册科技型企业1.8万家，国家级高新技术企业总数历史性突破1万家，拥有独角兽企业40家。参加海淀区级赛的优秀企业和项目是海淀区科技创新创业的缩影，也是海淀区落实“两新两高”战略、培育高精尖产业、构建创新“生态雨林”的蓬勃动力与不竭源泉。</p>
        <div class="firth">
          <div><img src="@/assets/img/beijing2.jpg" alt=""></div>
        </div>
        <p class="fourth-center">建设银行北京上地支行行长白煜发言</p>
        <p class="fourth-p2">建设银行北京上地支行行长白煜代表协办单位发言。他表示，建设银行北京中关村分行为解决科技型中小微企业“融资难、融资贵、融资慢”等问题，积极发挥普惠金融业务资源优势，借助金融科技，支持创新创业。作为海淀区级赛的特邀协办单位，建行中关村分行为入围的企业和项目均提供了“小微快贷”系列产品，以及科技企业信用贷、新三板股份质押贷款、“助知贷”等创新融资产品，积极助力科技型中小微企业快速成长。</p>
        <div class="firth">
          <div><img src="@/assets/img/1beijing3.jpg" alt=""></div>
        </div>
        <p class="fourth-center">北京市经信局中小企业处辛欣调研员发表致辞</p>
        <p class="fourth-p2">北京市经信局中小企业处辛欣调研员代表“创客北京”大赛组委会对海淀区级赛的成功举办表示热烈祝贺，并对海淀赛区的精心组织予以充分的肯定和赞赏。辛欣调研员指出，本届大赛实现了“四个升级”：一是规模升级，是去年参赛项目数量的3倍；二是品牌升级，推出了“创客北京”双创赛事品牌；三是体系升级，在包括海淀赛区在内的12个区设立区级复赛点；四是服务升级，为参赛的优秀企业和团队提供全方位的赛后服务支持。“创客北京”创新创业大赛已成为北京市中小企业服务体系建设的重要一环。</p>
        <div class="firth">
          <div><img src="@/assets/img/beijing4.jpg" alt=""></div>
        </div>
        <p class="fourth-center">海淀园创业服务中心主任赵新良作总结报告</p>
        <p class="fourth-p2">海淀园创业服务中心主任赵新良就海淀区赛事情况作总结汇报。他指出，本届海淀区级赛的参赛项目无论从数量还是质量上都亮点纷呈,特色显著。从项目征集到复赛评选历时近一个月，区内42家市级平台基地积极开展大赛宣传和项目征集工作，共征集项目532个，占全市项目总数的32.38%，项目数量在全市12个分赛区中位列首位。海淀赛区参赛项目数量多、水平高、质量好，通过举办大赛，也发掘和培育了一大批诸如顶象技术、亿华通、星河动力、安进医疗、复朗施、卡尤迪等有着高水平、高质量、自主原创的科技创新创业项目，充分彰显了海淀区雄厚的原始创新和自主创新实力。</p>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing5.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing6.jpeg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing7.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing8.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing9.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing10.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing11.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing12.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing13.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/beijing14.jpg" alt=""></div>
        </div>
        <p class="fourth-center">各领导为获奖企业和项目颁奖</p>
        <p class="fourth-p2">随后，与会领导为所有获奖企业颁发了奖杯和荣誉证书，获得海淀区级赛企业组、创客组高精尖产业特等奖的企业也进行了项目展示。海淀区级赛共评选出特等奖5名，一等奖5名，二等奖10名，三等奖15名，以及优胜奖20名。“基于人工智能的全覆盖式业务反欺诈系统”、“高端光学玻璃镜片制造”、“智能送电车”、“中国公用纺织品智慧租洗平台”、“钛搏进取号游戏电脑主机”5个项目脱颖而出，分别荣获企业组、创客组特等奖。</p>
        <div class="firth">
          <div><img src="@/assets/img/beijing15.jpg" alt=""></div>
        </div>
        <p class="fourth-center">大合照</p>
        <p class="fourth-p2">近年来，海淀区创新创业生态不断完善，服务体系持续优化，创新创业推动区域经济高质量发展，创新创业支撑区域新旧动能转换，新经济发展凸显新活力。2018年起，海淀园聚焦中关村科学城建设，立足原始创新策源地和自主创新主阵地的功能定位，推进创新生态提升和新型城市形态构建，努力挖掘释放内生动力，实现了向率先建成具有全球影响力的科学城的新迈进。众多优秀的创业项目在海淀科技创新的沃土上茁壮成长，蓄势待发，成为中关村科学城建设发展的新动力；高精尖产业经济结构在海淀新型城市形态中演绎一个又一个的精彩，成为推动全国“科技创新中心”建设发展的重要引擎。期待海淀区的优秀企业和创客在“创客北京”决赛中赛出精彩，再创佳绩</p>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="http://www.sohu.com/a/329558930_393767">http://www.sohu.com/a/329558930_393767</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newsix"><img src="@/assets/img/jinling1.jpg" alt=""></a>
            <div class="middle">
              <a href="newsix" class="h4">2019(第三届)全球青年创新大会金领奖年度盛典</a>
              <span class="middle-p1">全球青年创新大会</span>
              <span class="middle-p2">金领奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/10/24 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="neweight"><img src="@/assets/img/867.jpg" alt=""></a>
            <div class="middle">
              <a href="neweight" class="h4">中国（小谷围）人工智能创新创业大赛圆满落下帷幕</a>
              <span class="middle-p1">人工智能创新创业比赛</span>
              <span class="middle-p2">优胜奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/10 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/29 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
    text-align: center;
    margin-top: 16px;
    font-family: PingFang-SC-Bold;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #666666;
}
p{
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin:auto;
    padding: 30px;
    overflow: hidden;
    width: 1420px;
}
.left{
    width: 65%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 100%;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
            display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
